import Paper from "paper";
import SimplexNoise from "simplex-noise";

let config = {
  lintSpeed: 0.2,
  numLines: 5,
  lineSpacing: 20,
  lineSpeed: 1200,
  lineWidth: 2,
  colors: {
    original: [
      "#8F2D56",
      "#C75571",
      "#F0A8B7",
      "#F7D5D8",
      "#FFF4F2"
    ]
  },
  maxNoise: window.innerHeight * 0.07,
  maxPoints: 8
}

// an array of X positions for the points
let pointsX = Array(config.maxPoints);

  
// noise objects
const noiseObjects = [...Array(config.maxPoints).keys()].map(() => new SimplexNoise());

const hexToRgba = (hexStr) => {
  hexStr = hexStr.replace("#", "");

  return {
    r: parseFloat(parseInt(hexStr.substring(0, 2), 16)) / 255,
    g: parseFloat(parseInt(hexStr.substring(2, 4), 16)) / 255,
    b: parseFloat(parseInt(hexStr.substring(4, 6), 16)) / 255,
    a: 1
  }
}
  
// linear interpolation function
// lints a towards b with speed s
const lint = (a, b, s) =>{
  return a + (b - a) * s;
}
  
// applies lint to a color structure
const lintColor = (a, b, s) => {
  let newR = lint(a.r, b.r, s);
  let newG = lint(a.g, b.g, s);
  let newB = lint(a.b, b.b, s);
  let newA = lint(a.a, b.a, s);
  return { r: newR, g: newG, b: newB, a: newA };
}
  
const toPaperColor = (color) => {
  return new Paper.Color(color.r, color.g, color.b, color.a);
}

// updates vars to change with window
const handlePaperResize = () => {
  config.numPoints = Math.max(Math.min(Math.ceil(window.innerWidth / 250), config.maxPoints), 4);
  config.pointSpacing = Paper.view.size.width / (config.numPoints - 1);
  for (let i = 0; i < config.numPoints; i++) {
    pointsX[i] = i * config.pointSpacing;
  }
  config.lineOffsetY = Paper.view.size.height * 0.75;
}

// draws the lines onto the canvas
const initPaper = () => {
  handlePaperResize();

  config.colors.original = config.colors.original.map(c => hexToRgba(c));
  
  // reduced colors for the rest of the page
  config.colors.reduced = config.colors.original.map(c => {
    return {r: c.r, g: c.g, b: c.b, a: c.a * 0.1 };
  });
  
  // color that is currently being drawn
  let currentColors = config.colors.original.map(c => Object.assign({}, c));
  
  // create paths
  let lines = [];
  
  for (let i = 0; i < config.numLines; i++) {
    let line = new Paper.Path();
    line.strokeColor = config.colors.original[i];
    line.strokeWidth = config.lineWidth;
    
    for (let j = 0; j < config.numPoints; j++) {
      line.add(new Paper.Point());
    }
  
    lines.push(line);
  }
  
  // paper.js loop
  Paper.view.onFrame = ev => {
    // update colors (lint towards target colors)
    let targetColors = window.scrollY == 0 ? config.colors.original : config.colors.reduced;
    currentColors = currentColors.map((c, i) => {
      c = lintColor(c, targetColors[i], config.lintSpeed);
      return c;
    });

    // add or remove points if resized
    if (lines[0].segments.length > config.numPoints) {
      lines.forEach(line => line.removeSegment(line.segments.length - 1));
    }
    if (lines[0].segments.length < config.numPoints) {
      lines.forEach(line => line.addSegment(new Paper.Point()));
    }
  
    // loop through each line
    lines.forEach((line, i) => {
      // set color
      line.strokeColor = toPaperColor(currentColors[i]);
  
      // apply noise (same seed for each line)
      line.segments.forEach((seg, j) => {
        const noise = noiseObjects[j].noise2D(ev.count / config.lineSpeed, 0);
  
        seg.point.set(pointsX[j], config.lineOffsetY + noise * config.maxNoise + i * config.lineSpacing);
      });
      
      line.smooth();
    });
  
    Paper.view.draw();
  }
  
  // fix point spacing on window resize
  Paper.view.onResize = handlePaperResize;
}

export { initPaper };
