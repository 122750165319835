import "./Navbar.scss";

import { CgFileDocument } from "react-icons/cg";
import { FaLinkedin } from "react-icons/fa";
import { FiGithub } from "react-icons/fi";
import { ReactComponent as Logo } from "../assets/logo.svg";
import React from "react";
import Resume from "../assets/resume.pdf"; // 2022.12.03
import { SiDevpost } from "react-icons/si";

class Navbar extends React.Component {
  render() {
    return (
      <div className={"navbar" + (this.props.home ? " home" : "")}>
        <div className="left">
          <span
            className="logo"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <Logo />
          </span>
        </div>
        <div className="right">
          <a
            className="icon-link"
            href="https://github.com/emily-emily"
            target="_blank"
          >
            <FiGithub />
          </a>
          <a
            className="icon-link"
            href="https://www.linkedin.com/in/emily-yhl"
            target="_blank"
          >
            <FaLinkedin />
          </a>
          <a
            className="icon-link"
            href="https://devpost.com/emily-emily"
            target="_blank"
          >
            <SiDevpost />
          </a>
          <a
            className="icon-link"
            href="https://emilyyu.ca/react-resume/"
            target="_blank"
          >
            <CgFileDocument />
          </a>
          {/* <a className="icon-link" href={Resume} target="_blank">
            <CgFileDocument />
          </a> */}
        </div>
      </div>
    );
  }
}

export default Navbar;
