import "./AboutSection.scss";

import React from "react";
import Section from "./Section";

class AboutSection extends React.Component {
  render() {
    return (
      <Section title="About Me" section={"about-section"} id={this.props.id}>
        <div className="content-wrapper">
          <p>
            I am a Computer Science student at the University of Waterloo. My
            experience includes software and full-stack development, and my most
            recent internship experiences include Pinecone and Microsoft. I'm also
            excited to dive into machine learning and artificial intelligence in
            my work and studies!
          </p>
          <p>Here are some languages and technologies that I use:</p>
          <ul className="tech-stack">
            <li>Python</li>
            <li>Rust</li>
            <li>C++</li>
            <li>JavaScript/Typescript</li>
            <li>React, Express</li>
          </ul>
          <p>
            Outside of academics, I regularly play badminton and participate in
            chamber ensembles as a cellist. I love to marvel at cool website and
            product design from time to time, as well as dabble in creative
            hobbies like recording music and photography. See my{" "}
            <a href="https://www.bandlab.com/aezara" target="_blank">
              BandLab
            </a>!
          </p>
          <p>
            I also love to attend hackathons! I placed as a finalist with my
            team at Hack the North 2021, Canada's Biggest Hackathon. Visit my{" "}
            <a href="https://devpost.com/emily-emily" target="_blank">
              Devpost
            </a>{" "}
            for some of my latest hacks.
          </p>
        </div>
      </Section>
    );
  }
}

export default AboutSection;
